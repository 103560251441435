<ul class="navbar-nav" *ngIf="isAuthenticated | async">
  <li class="nav-item">
    <button [matMenuTriggerFor]="menu"><i class="lni-user"></i></button>
    <mat-menu #menu="matMenu">
      <span mat-menu-item> {{ userName | async }}</span>
      <hr />

      <div mat-menu-item *ngIf="isAdmin">
        Credite SMS:
        <div>
          {{currentCredits}} / {{fromCredits}}
        </div>
      </div>

      <hr *ngIf="isAdmin" />
      <!--<button mat-menu-item class="align-center" (click)="openOrders()" *ngIf="isAdmin">Comenzi</button>
  <button mat-menu-item class="align-center" (click)="openOperators()" *ngIf="isAdmin">Operatori</button>
  <button mat-menu-item class="align-center" (click)="openLeads()" *ngIf="isAdmin">Gestiune Lead-uri</button>
  <button mat-menu-item class="align-center" (click)="openExtraStatus()" *ngIf="isAdmin">Gestiune Extra Status</button>
  <button mat-menu-item class="align-center" (click)="openOperatorTracking()" *ngIf="isAdmin">Accesari Operatori</button>
  <button mat-menu-item class="align-center" (click)="openBlackList()" *ngIf="isAdmin">Gestionare Black List</button>
  <button mat-menu-item class="align-center" (click)="openReturnOrders()" *ngIf="isAdmin">Gestionare Retururi</button>
  <button mat-menu-item class="align-center" (click)="openChangePassword()" *ngIf="isAdmin">Modifica Parola</button>-->
      <button mat-menu-item class="align-center">
        <a class="nav-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">Logout</a>
      </button>
    </mat-menu>
  </li>
</ul>
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <li class="nav-item">
    <a class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Login</a>
  </li>
</ul>
