import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrl: './intro.component.css'
})
export class IntroComponent implements OnInit {

  divVisible: boolean = true;

  ngOnInit() {
    setTimeout(() => {
      this.divVisible = false;
    }, 3600); // Hide the div after 3.6 seconds
  }

}
