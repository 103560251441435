import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../constants/common.constants';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { LeadListRequest } from '../interfaces/lead-list-request';
import { Observable } from 'rxjs';
import { ILead } from '../interfaces/lead.interface';
import { DataSourceResponse } from '../interfaces/data-source-response';
import { map } from 'rxjs/operators';
import { ILeadStatus } from '../interfaces/lead-status.interface';
import { IExtraStatusApply } from '../interfaces/lead-import-response.interface';
import { IOffice } from '../interfaces/office';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  private _baseUrl: string = `${ApiBaseUrl}lead`;
  private _importBaseUrl: string = `${ApiBaseUrl}leadsfileimport`;

  constructor(private httpClient: HttpClient) { }

  getLeads(request: LeadListRequest): Observable<DataSourceResponse<ILead>> {
    return this.httpClient.post<DataSourceResponse<ILead>>(`${this._baseUrl}`, request);
  }

  deleteAllLeads(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/deleteAll`);
  }

  deleteLead(lead: ILead): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, lead);
  }

  getLeadStatus(): Observable<ILeadStatus[]> {
    return this.httpClient.get<ILeadStatus[]>(`${this._baseUrl}/leadStatus`);
  }

  saveGuidInSession(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/saveInSession`);
  }

  getNextLead(): Observable<ILead> {
    return this.httpClient.get<ILead>(`${this._baseUrl}/nextLead`);
  }

  makeCall(lead: ILead): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/makeCall`, lead);
  }

  updateLead(lead: ILead): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, lead);
  }


  updateStatus(lead: ILead): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/updateStatus`, lead);
  }

  changeExtraStatus(extraStatusApply: IExtraStatusApply): Observable<number> {
    return this.httpClient.post<number>(`${this._baseUrl}/changeextrastatus`, extraStatusApply);
  }

  getTotalAvailableLeads(): Observable<number> {
    return this.httpClient.get<number>(`${this._baseUrl}/countLeads`);
  }



  uploadFile(data: any) {
    return this.httpClient.post<any>(`${this._importBaseUrl}/upload`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  }

}
