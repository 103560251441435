import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../constants/common.constants';
import { ISmsCredit } from '../interfaces/sms-credit.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementService {

  private _baseUrl: string = `${ApiBaseUrl}manageaccount`;

  constructor(private httpClient: HttpClient) { }

  getAllUsers() :Observable<any> {
    return this.httpClient.get<any>(`${this._baseUrl}`);
  }

  changePassword(oldPassword: string, newPassword: string, username: string): Observable<boolean> {
    const params = new HttpParams().set("oldPassword", oldPassword).set("newPassword", newPassword).set("username", username);
    return this.httpClient.post<boolean>(`${this._baseUrl}/changePassword`, params)
  }


  getCredits(): Observable<ISmsCredit> {
    return this.httpClient.get<any>(`${this._baseUrl}/sms-credit`);
  }
}
