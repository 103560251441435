import { Component, OnInit } from '@angular/core';
import { OperatorTrackService } from '../../../management/core/services/opearator-track.service';

@Component({
  selector: 'app-management-layout',
  templateUrl: './management-layout.component.html',
  styleUrls: ['./management-layout.component.css']
})
export class ManagementLayoutComponent implements OnInit {

  constructor(private operatorTrackService: OperatorTrackService) { }

  ngOnInit() {
    this.operatorTrackService.checkAddress().subscribe();
  }

}
