<header>
  <nav class='navbar navbar-expand-sm navbar-toggleable-sm fixed-top box-shadow'>
    <div class="logo"></div>
    <div class="container-fluid">

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]='{"show": isExpanded}'>
        <app-login-menu></app-login-menu>
        <ul class="navbar-nav flex-grow">
        </ul>
      </div>
    </div>
  </nav>
</header>
