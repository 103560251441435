import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountManagementService } from '../../app/management/core/services/account-management.service';
import { UserRoles } from '../api-authorization.constants';
import { AuthorizeService } from '../authorize.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public userRole: string;
  public isAdmin: boolean = false;

  public currentCredits: string = "0";
  public fromCredits: string = "0";

  constructor(private authorizeService: AuthorizeService,
    private router: Router,
    private accountService: AccountManagementService  ) { }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    this.authorizeService.getUserRole().subscribe(y => {
      this.userRole = y.toLowerCase();
      if (y == UserRoles.SuperAdmin) {
        this.userRole = 'admin'
        this.isAdmin = true;

        if (this.fromCredits == "0" && this.currentCredits=="0") {
          this.accountService.getCredits().subscribe(x => {
            if (x == null) {
              return;
            }

            this.currentCredits = this.numberWithSpaces(x.currentCreditAmount);
            this.fromCredits = this.numberWithSpaces(x.rechargeCreditAmount);
          })
        }
      }

    });

  }

  openOrders() {
    this.router.navigate([`management/${this.userRole}/orders`]);
  }

  openOperators() {
    this.router.navigate([`management/${this.userRole}/operators`]);
  }

  openLeads() {
    this.router.navigate([`management/${this.userRole}/leads`]);
  }

  openExtraStatus() {
    this.router.navigate([`management/${this.userRole}/extra-status`]);
  }

  logout() {
    this.authorizeService.completeSignOut("/authentication/login");
  }

  openOperatorTracking() {
    this.router.navigate([`management/${this.userRole}/operator-tracking`]);
  }

  openBlackList() {
    this.router.navigate([`management/${this.userRole}/black-list`]);
  }

  openReturnOrders() {
    this.router.navigate([`management/${this.userRole}/return-order`]);
  }
  
  openChangePassword() {
    this.router.navigate([`management/${this.userRole}/account-management`]);
  }

  numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  
}
