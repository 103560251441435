import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ApiAuthorizationModule } from '../../api-authorization/api-authorization.module';
import { ManagementHeaderComponent } from './management/management-header/management-header.component';
import { ManagementLayoutComponent } from './management/management-layout/management-layout.component';
import { IntroComponent } from './management/intro/intro.component';

@NgModule({
  declarations:
    [
      IntroComponent,
      ManagementHeaderComponent,
      ManagementLayoutComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    ApiAuthorizationModule,
    MatMenuModule
  ],
  exports: [
    ManagementLayoutComponent,
    ManagementHeaderComponent,
  ]
})
export class LayoutModule { }
