import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../constants/common.constants';
import { DataSourceResponse } from '../interfaces/data-source-response';
import { IOperatorIpAddress } from '../interfaces/operator-ip-address';
import { OperatorTrackRequest } from '../interfaces/operator-track-request';

@Injectable({
  providedIn: 'root'
})
export class OperatorTrackService {

  private _baseUrl: string = `${ApiBaseUrl}operatortrack`;

  constructor(private httpClient: HttpClient) { }

  checkAddress(): Observable<DataSourceResponse<boolean>> {
    return this.httpClient.get<DataSourceResponse<boolean>>(`${this._baseUrl}`);
  }

  getOperatorAddressess(request: OperatorTrackRequest): Observable<DataSourceResponse<IOperatorIpAddress>> {
    return this.httpClient.post<DataSourceResponse<IOperatorIpAddress>>(`${this._baseUrl}`, request);
  }

}
