import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { ManagementLayoutComponent } from './layout/management/management-layout/management-layout.component';

const routes: Routes = [
  {
    path: 'management',
    component: ManagementLayoutComponent,
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [AuthorizeGuard]
  },
  {
    path: '',
    redirectTo: '/Identity/Account/Login',
    pathMatch: 'prefix'
  },
  {
    path: '**',
    component: ManagementLayoutComponent,
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule),
    canActivate: [AuthorizeGuard]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
